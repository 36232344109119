<template>
  <shared-cart-container />
</template>

<script>
  import SharedCartContainer from 'src/shared/features/cart/cart.container';

  export default {
    name: 'CartView',
    components: {
      SharedCartContainer,
    },
  };
</script>

<style></style>
